
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';

  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { IreInvestmentAreaObject } from '@/store/modules/IRE/IreInvestmentAreasModule';

  export default defineComponent({
    name: 'ire-investment-areas-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    data: () => ({
      selectedDataConfig: '',
    }),
    methods: {
      sendInfo(item, type) {
        this.selectedDataConfig = item;
        this.selectedDataConfig['type'] = type;
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitSizeButton = ref<HTMLButtonElement | null>(null);

      const submitShopPricesButton = ref<HTMLButtonElement | null>(null);
      const submitBasementPricesButton = ref<HTMLButtonElement | null>(null);
      let showSaveButtonShop = ref<boolean>(true);
      let showSaveButtonBasement = ref<boolean>(true);
      let disabledEditPercentages = ref<boolean>(false);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('areasIreValuesUpdating'), [
          translate('ire'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_IRE_INVESTMENT_AREA,
        route.params.id
      );

      const area = ref<IreInvestmentAreaObject>(data.data);
      const baseValue = area.value.ireInvestmentSizeQualityConfig[0].value;
      if (area.value.customUpdatedAt) {
        const dateString = new Date(area.value.customUpdatedAt);

        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        area.value.customUpdatedAt = `${year}-${month}-${day}`;
      } else {
        area.value.customUpdatedAt = '';
      }
      const locations = await store.dispatch(
        Actions.GET_IRE_INVESTMENT_ALL_LOCATIONS
      );
      const locationData = locations.data.data;

      const sizeQualityConfig =
        area.value.ireInvestmentSizeQualityConfig.reduce((groups, item) => {
          const key = item.qualityId;
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(item);
          return groups;
        }, {});

      // sizeQualityConfig.sort(fieldSorter(['city', '-price']));

      let shopLocationConfig: any[];
      let basementLocationConfig: any[];

      if (area.value.locationsConfig.length > 0) {
        shopLocationConfig = area.value.locationsConfig.filter((p) => {
          return p.locationTypeId == 2;
        });
        basementLocationConfig = area.value.locationsConfig.filter((p) => {
          return p.locationTypeId == 3;
        });
      } else {
        shopLocationConfig = locationData
          .map((p) => {
            if (p['type'] === 2) {
              store.dispatch(
                Actions.REMOVE_BODY_CLASSNAME,
                'page-loading-over'
              );

              return {
                locationId: p.id,
                pricepermeter: null,
                locationsTitle: p['titleAr'],
                locationTypeId: p['type'],
              };
            }
          })
          .filter((element) => element !== undefined);

        basementLocationConfig = locationData
          .map((p) => {
            if (p['type'] === 3) {
              store.dispatch(
                Actions.REMOVE_BODY_CLASSNAME,
                'page-loading-over'
              );

              return {
                locationId: p.id,
                pricepermeter: null,
                locationsTitle: p['titleAr'],
                locationTypeId: p['type'],
              };
            }
          })
          .filter((element) => element !== undefined);
      }
      if (basementLocationConfig.length == 0) {
        basementLocationConfig = locationData
          .map((p) => {
            if (p['type'] === 3) {
              store.dispatch(
                Actions.REMOVE_BODY_CLASSNAME,
                'page-loading-over'
              );

              return {
                locationId: p.id,
                pricepermeter: null,
                locationsTitle: p['titleAr'],
                locationTypeId: p['type'],
              };
            }
          })
          .filter((element) => element !== undefined);
      }
      if (shopLocationConfig.length == 0) {
        shopLocationConfig = locationData
          .map((p) => {
            if (p['type'] === 2) {
              store.dispatch(
                Actions.REMOVE_BODY_CLASSNAME,
                'page-loading-over'
              );

              return {
                locationId: p.id,
                pricepermeter: null,
                locationsTitle: p['titleAr'],
                locationTypeId: p['type'],
              };
            }
          })
          .filter((element) => element !== undefined);
      }

      const propertyAgeConfig = area.value.propertyAgeConfig.reduce(
        (groups, item) => {
          const key = item.propertyAgeId;
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(item);
          return groups;
        },
        {}
      );

      const onSubmitShopConfig = async () => {
        const shopConfig = shopLocationConfig.reduce(
          (previousValue, currentValue) => {
            previousValue.push({
              id: currentValue.id,
              locationId: currentValue.locationId,
              pricepermeter: currentValue.pricepermeter,
            });
            return previousValue;
          },
          []
        );

        const payload = {
          areaId: area.value?.id,
          shopConfig,
        };
        if (submitShopPricesButton.value) {
          // eslint-disable-next-line
          submitShopPricesButton.value!.disabled = true;
          submitShopPricesButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_SHOP_CONFIG, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitShopPricesButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitShopPricesButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_ADDED_SHOP_CONFIG'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            if (!shopConfig[0].id) showSaveButtonShop.value = false;
            //Deactivate indicator
            submitShopPricesButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitShopPricesButton.value!.disabled = false;
          });
        }
      };

      const onSubmitBasementConfig = async () => {
        const shopConfig = basementLocationConfig.reduce(
          (previousValue, currentValue) => {
            previousValue.push({
              id: currentValue.id,
              locationId: currentValue.locationId,
              pricepermeter: currentValue.pricepermeter,
            });
            return previousValue;
          },
          []
        );

        const payload = {
          areaId: area.value?.id,
          shopConfig,
        };
        if (submitBasementPricesButton.value) {
          // eslint-disable-next-line
          submitBasementPricesButton.value!.disabled = true;
          submitBasementPricesButton.value.setAttribute(
            'data-kt-indicator',
            'on'
          );
        }

        await store.dispatch(Actions.UPDATE_SHOP_CONFIG, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitBasementPricesButton.value?.removeAttribute(
            'data-kt-indicator'
          );
          // eslint-disable-next-line
          submitBasementPricesButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            if (!shopConfig[0].id) showSaveButtonBasement.value = false;
            //Deactivate indicator
            submitBasementPricesButton.value?.removeAttribute(
              'data-kt-indicator'
            );
            // eslint-disable-next-line
            submitBasementPricesButton.value!.disabled = false;
          });
        }
      };

      const onSubmitDataConfig = async (item) => {
        let payload = {
          id: item.id,
          value: Number(item.value),
          size: Number(item.sizeTitle),
        };

        if (item.type == 1) {
          payload['increasePercentage'] = Number(item.increasePercentage);

          await store.dispatch(Actions.UPDATE_QUALITY_CONFIG, payload);
        } else if (item.type == 2) {
          payload['decreasedPercentage'] = Number(item.decreasedPercentage);
          await store.dispatch(Actions.UPDATE_PROPERTY_AGE_CONFIG, payload);
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitBasementPricesButton.value?.removeAttribute(
            'data-kt-indicator'
          );
          // eslint-disable-next-line
          submitBasementPricesButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitBasementPricesButton.value?.removeAttribute(
              'data-kt-indicator'
            );
            // eslint-disable-next-line
            submitBasementPricesButton.value!.disabled = false;
          });
        }
      };

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const areaSchema = Yup.object().shape({
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
        firstIncreasePercentage: Yup.string().when('editPercentages', {
          is: true,
          then: Yup.string().required(() =>
            translate('AREA_FIRST_INCR_IS_REQUIRED_FIELD')
          ),
        }),
        secondIncreasePercentage: Yup.string().when('editPercentages', {
          is: true,
          then: Yup.string().required(() =>
            translate('AREA_SECOND_INCR_IS_REQUIRED_FIELD')
          ),
        }),
        firstDecreasedPercentage: Yup.string().when('editPercentages', {
          is: true,
          then: Yup.string().required(() =>
            translate('AREA_FIRST_DESC_IS_REQUIRED_FIELD')
          ),
        }),
        secondDecreasedPercentage: Yup.string().when('editPercentages', {
          is: true,
          then: Yup.string().required(() =>
            translate('AREA_SECOND_DESC_IS_REQUIRED_FIELD')
          ),
        }),
      });
      const onSubmitUpdate = async (values) => {
        delete values.AreasName;

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          baseValue: Number(values.baseValue),
          isSeaView: values.isSeaView ? true : false,
          editPercentages: values.editPercentages ? true : false,
          firstIncreasePercentage: values.firstIncreasePercentage
            ? Number(values.firstIncreasePercentage)
            : null,
          secondIncreasePercentage: values.secondIncreasePercentage
            ? Number(values.secondIncreasePercentage)
            : null,
          firstDecreasedPercentage: values.firstDecreasedPercentage
            ? Number(values.firstDecreasedPercentage)
            : null,
          secondDecreasedPercentage: values.secondDecreasedPercentage
            ? Number(values.secondDecreasedPercentage)
            : null,
          sort: Number(values.sort),
          customUpdatedAt: values.customUpdatedAt
            ? values.customUpdatedAt
            : undefined,
        };
        await store.dispatch(Actions.UPDATE_IRE_INVESTMENT_AREA, {
          id: area.value?.id,
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.go(0);
          });
        }
      };

      const fillPercentages = async (baseValue) => {
        console.log(baseValue);
        console.log(area.value.ireInvestmentSizeQualityConfig[0].value);

        if (area.value.ireInvestmentSizeQualityConfig[0].value == baseValue) {
          disabledEditPercentages.value = false;
          area.value.editPercentages = false;
          area.value.firstDecreasedPercentage = null!;

          area.value.secondDecreasedPercentage = null!;

          area.value.firstIncreasePercentage = null!;

          area.value.secondIncreasePercentage = null!;
        } else {
          disabledEditPercentages.value = true;
          area.value.editPercentages = true;

          area.value.firstDecreasedPercentage =
            propertyAgeConfig[2][0].decreasedPercentage;
          area.value.secondDecreasedPercentage =
            propertyAgeConfig[3][0].decreasedPercentage;
          area.value.firstIncreasePercentage =
            sizeQualityConfig[2][0].increasePercentage;
          area.value.secondIncreasePercentage =
            sizeQualityConfig[3][0].increasePercentage;
        }
      };
      // await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
      //   type: 'ire-investment',
      // });

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        disabledEditPercentages,
        fillPercentages,
        showSaveButtonBasement,
        showSaveButtonShop,
        onSubmitDataConfig,
        submitBasementPricesButton,
        submitShopPricesButton,
        baseValue,
        onSubmitShopConfig,
        onSubmitBasementConfig,
        shopLocationConfig,
        basementLocationConfig,
        propertyAgeConfig,
        sizeQualityConfig,
        submitSizeButton,
        onSubmitUpdate,
        submitButton,
        translate,
        areaSchema,
        goBack,
        area,
        can,
      };
    },
  });
